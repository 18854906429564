var successResponseCallback = function() {
    window.location = '/offers';
};

var invalidResponseCallback = function() {
    window.location = '/offers';
};

var offersRedirect = function() {
    window.location = '/resources';
};

var generatePostData = function(formId) {
    if (formId === null || formId === undefined) {
        formId = 'main-form';
    }

    var dataToPost = {};
    $('#' + formId + ' :input').each(function(i, el) {
        el = $(el);
        if (el.attr('type') === 'radio' || el.attr('type') === 'checkbox') {
            if (!el.is(':checked')) {
                return;
            }
        }
        dataToPost[el.attr('name')] = el.val();
    });

    return dataToPost;
};

var stepHandler = function(step, maxStep) {
    if (step === maxStep) {
        setTimeout(function() {
            offersRedirect();
        }, 150);
    } else {
        var nextStep = parseInt(step + 1);
        $('*[step="' + step + '"]').addClass('hidden');
        $('*[step="' + nextStep + '"]').removeClass('hidden');
    }
};

var processForm = function() {
    var postData = generatePostData();

    $('#leadresponse_container').load('/process', postData, function() {
        var leadResponse = $('input#avt_lead_response').val();
        if (leadResponse === 'success') {
            successResponseCallback();
        } else {
            invalidResponseCallback();
        }
    });
};

var processOfferForm = function(formId, maxStep) {
    var postData = generatePostData(formId);
    var step = parseInt($('#' + formId).attr('form-step'));
    $.ajax({
        url: $('#' + formId).attr('action'),
        type: 'POST',
        dataType: 'json',
        data: postData
    }).always(function() {
        setTimeout(function() {
            stepHandler(step, maxStep);
        }, 1000);
    });
};

var zipLookup = function(zipcode) {
    $.getJSON('/zipcode?zip=' + zipcode, function(data) {
        $("#pi-form input[name='address[city]']").val(data.city);
        if ($('#bk-form').length > 0) {
            $("#bk-form input[name='address[city]']").val(data.city);
        }
        if ($('#ssd-form').length > 0) {
            $("#ssd-form input[name='city']").val(data.city);
        }
    });
};

$('input').blur(function() {
    $(this)
        .parsley()
        .validate();
});

$(document).ready(function() {

    $('.zipcode').change(function() {
        var zipCode = $(this).val();
        if (zipCode.length === 5) {
            zipLookup(zipCode);
        }
    });

    if ($('#zip').length > 0) {
        zipLookup($('#zip').val());
    }

    var maxStep = 0;
    $('form').each(function(i, el) {
        maxStep++;
        $(el).attr('form-step', i + 1);
        $(el)
            .closest('[id*=questions]')
            .attr('step', i + 1);
    });

    $('.next-btn').on('click', function(e) {
        e.preventDefault();
        var step = parseInt(
            $(this)
                .closest('form')
                .attr('form-step')
        );
        stepHandler(step, maxStep);
    });

    $('.btn--yes').on('click', function(e) {
        e.preventDefault();
        $(this)
            .closest('form')
            .children('.form-steps')
            .removeClass('hidden');
    });

    $('label.btn, label.skip-btn').on('click', function() {
        $(this)
            .parent()
            .siblings()
            .children()
            .attr('checked', false);
        $(this)
            .siblings()
            .attr('checked', true);
       $(this).siblings().parsley().validate(); 
    });

    $('.form-submit').on('click', function(e) {

        e.preventDefault();

        var form = $(this).closest('form');
        var formId = form.attr('id');

        form.parsley().validate();

        if (formId === 'main-form') {
            if (form.parsley().isValid() === true) {
                processForm();
                return;
            }
            form.parsley().validate();
        } else {
            if (form.parsley().isValid() === true) {
                $(this).attr('disabled', true);
                $('.contact-information').each(function(i, el) {
                    $(el).addClass('hidden');
                });
                processOfferForm(formId, maxStep);
            } else {
                form.parsley().validate();
                $('html, body').animate({
                    scrollTop: $(form).offset().top
                }, 250);
            }
        }
    });

    $('#pi_attorney_yes').on('click', function() {
        $('#attorney_name').removeClass('hidden');
    });

    $('#pi_attorney_no').on('click', function() {
        $('#attorney_name').addClass('hidden');
    });

    $('input[name=hospitalized]').siblings('label').on('click', function() {
        if ($('input[name=hospitalized]:checked').val() === 'No') {
            $('#physically_injured').removeClass('hidden');
            $('input[name=physically_injured]').attr('data-parsley-required', true);
        } else {
            $('#physically_injured').addClass('hidden');
            $('input[name=physically_injured]').attr('data-parsley-required', false);
        }
    });

    $('*[bind]').on('change keyup', function() {
        var to_bind = $(this).attr('bind');
        $("*[bind='" + to_bind + "']").val($(this).val());
    });

    $('select[name=pi_injury_cause').on('change', function() {
        if ($(this).val() === 'Work Related Accident') {
            $('.wc-accident').removeClass('hidden');
            $('.mva-accident').addClass('hidden');
        } else {
            $('.wc-accident').addClass('hidden');
            $('.mva-accident').removeClass('hidden');
        }

        $('#pi-form-questions').removeClass('hidden');
    });

    if ($('#currentage').length > 0) {
        $('#currentage').on('change', function() {
            var date = new Date();
            date.setFullYear(date.getFullYear() - $('#currentage').val());
            $('#dob').val(
                date.getMonth() +
                    '/' +
                    date.getDate() +
                    '/' +
                    date.getFullYear()
            );
        });
    }

    $('footer a').click(function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var day = new Date();
        var id = day.getTime();
        window.open(
            $(this).attr('href'),
            id,
            'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=0,width=400,height=300,left=500,top=500'
        );
        return false;
    });
});

